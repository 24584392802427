import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './bulkMaintenanceResultsDownloadPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import Breadcrumb from '../../components/Breadcrumb';
import BaseTable from '../../components/BaseTable';
import Button from '../../components/Button/button';
import LoadingOverlay from '../../components/LoadingOverlay';
import AGUtils from '../../utils/ag-grid.utils';
import BaseText from '../../components/BaseText/BaseText';
import { useMessageModal } from '../../hooks/modal.hook';
import {
  useDocumentMaintenanceGetBulkChangeListApi,
  useDownloadBulkChangeErrorFileApi,
} from '../../hooks/api/documentsMaintenance.hook';
import { BulkChangeItem } from '../../services/http/documentsMaintenance.api';
import { useDownloadFile } from '../../hooks/file.hook';
import routes from '../../utils/routes';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * セル'ステータス'のコンポーネントインターフェース
 */
export interface StatusCellRenderProps {
  /** テーブルセルレンダリングされるログデータ */
  data: BulkChangeItem;
  /** "リロード" ボタンがクリックされた時に実行されるハンドラー */
  onReloadClick: () => void;
}

/**
 * セル'ボタン'のコンポーネントインターフェース
 */
export interface BtnCellRenderProps {
  /** テーブルセルレンダリングされるログデータ */
  data: BulkChangeItem;
  /** "ダウンロード" ボタンがクリックされた時に実行されるハンドラー */
  onDownloadClick: (data: BulkChangeItem) => void;
}

export function TableStatusCellRender({
  data, onReloadClick,
}: StatusCellRenderProps) {
  const showReloadButton = data.changeStatus === 0;

  let text = '';
  if (data.changeResult === 1) {
    text = 'エラー';
  } else if (data.changeStatus === 0) {
    text = '処理中';
  } else if (data.changeStatus === 1) {
    text = '完了';
  }

  return (
    <div className={styles.importButtonContainer}>
      <div className={styles.refreshContainer}>
        {
            (showReloadButton && (
            <div>
              <img
                style={{ marginLeft: '-15px' }}
                onClick={onReloadClick}
                alt="reload"
                src="/images/Reload.svg"
                className={styles.refreshButton}
              />
            </div>
            ))
          }
      </div>
      <div>{text}</div>
    </div>
  );
}

export function TableBtnCellRender({
  data, onDownloadClick,
}: BtnCellRenderProps) {
  return (
    <div className={styles.importButtonContainer}>
      {data.changeResult === 1 && (
        <button
          className={[styles.tableButton, mainStyles['btn-gray']].join(' ')}
          type="button"
          onClick={() => onDownloadClick(data)}
        >
          ダウンロード
        </button>
      )}
    </div>
  );
}

export function CustomSearchHeader({ displayName }: { displayName?: string }) {
  return <div className={styles.customSearchtHeader}>{displayName}</div>;
}

function Component() {
  usePageTitle('一括メンテ結果一覧');
  const navigate = useNavigate();

  const tableMinWidth = (window.innerWidth - 100).toString();

  // hooks
  const openMessageModal = useMessageModal();
  const downloadFile = useDownloadFile();

  // API's
  const { request: bulkChangeListRequest, loading: bulkChangeListLoading } = useDocumentMaintenanceGetBulkChangeListApi();
  const { request: downloadBulkChangeErrorFile } = useDownloadBulkChangeErrorFileApi();
  const { request: createLog } = useCreateLogApi();

  // states
  const [bulkChangeList, setBulkChangeList] = useState<BulkChangeItem[]>();

  // methods
  const onGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  };

  const getBulkChangeList = useCallback(async () => {
    try {
      const res = await bulkChangeListRequest();
      setBulkChangeList(res);
    } catch (e) {
      openMessageModal((e as Error)?.message);
    }
  }, [openMessageModal, bulkChangeListRequest]);

  const onReloadClick = useCallback(() => {
    getBulkChangeList();
  }, [getBulkChangeList]);

  const onDownloadClick = useCallback(async (data: BulkChangeItem) => {
    try {
      const bulkChangeErrorFile = await downloadBulkChangeErrorFile(data.changeKey);
      downloadFile(bulkChangeErrorFile);
    } catch (e) {
      openMessageModal((e as Error)?.message);
    }
  }, [downloadBulkChangeErrorFile, downloadFile, openMessageModal]);

  const columnDefs = [
    AGUtils.colDefault('changeKey', '処理番号', 200),
    {
      headerName: '処理状況',
      resizable: true,
      field: 'condition',
      cellRenderer: TableStatusCellRender,
      cellRendererParams: {
        onReloadClick,
      },
      width: 170,
    },
    AGUtils.colDate('registDate', '実行日時'),
    AGUtils.colDefault('registUser', 'ユーザー名', 200),
    {
      headerName: '処理結果',
      resizable: true,
      field: 'searchResult',
      width: 230,
      valueFormatter: (params: ValueFormatterParams) => {
        const { numberCases, numberSuccess } = params.data;
        return `${numberSuccess}件 / ${numberCases}件`;
      },
    },
    {
      headerName: '失敗データ',
      resizable: true,
      field: 'condition',
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onDownloadClick,
      },
      width: 170,
      headerComponentFramework: CustomSearchHeader,
    },
    {
      field: '',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
    },
  ];

  // effects
  useEffect(() => {
    getBulkChangeList();
  }, []);

  useEffectOnce(() => {
    createLog(LogFormName.BulkMaintenanceResultList, LogControlName.Show);
  });

  return (
    <MainFrame body={(
      <div className={styles.mainFrame}>
        <Breadcrumb crumbs={[
          { label: '寺田倉庫用', route: routes.bpoMenuScreen },
          { label: document.title }]}
        />
        <div className={styles.mainFrameBody}>
          <div className={styles.bodyContentTop}>
            <div className={styles.bodyContentTopLeft}>
              <div>
                <Button size="smaller" onClick={() => navigate(routes.bulkTagsUpdateScreen)}>
                  新規メンテ登録
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.bodyContentMain}>
            <div className={styles.bodyContentMainLeft}>
              <div style={{ height: '100%' }}>
                <BaseTable
                  formName="searchList"
                  rowData={bulkChangeList}
                  onGridReady={onGridReady}
                  minWidthProp={tableMinWidth}
                  columnDefs={columnDefs}
                />
              </div>
            </div>
          </div>
        </div>
        {(bulkChangeListLoading) && <LoadingOverlay />}
      </div>
      )}
    />
  );
}

export default Component;
