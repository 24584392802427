export enum SearchStatus {
  IN_PROGRESS = 0,
  COMPLETED = 1,
}

export enum SearchResult {
  SUCCESS = 0,
  ERROR = 1,
}

export const DocumentFields = {
  CC_CONTROL_NUMBER: '文書ID',
  UPDATE_DATE: '更新日時',
};
