/* eslint-disable no-plusplus */

import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import * as uuid from 'uuid';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import Papa, { ParseResult } from 'papaparse';
import { Divider } from '@mui/material';
import * as XLSX from 'xlsx';
import usePageTitle from '../../hooks/title.hook';
import MainFrame from '../../components/MainFrame/mainFrame';
import mainStyles from '../main.module.css';
import styles from './bulkTagsUpdateScreenPage.module.css';
import Button from '../../components/Button/button';
import routes from '../../utils/routes';
import { useMessageModal } from '../../hooks/modal.hook';
import BaseTable from '../../components/BaseTable';
import AGUtils from '../../utils/ag-grid.utils';
import TagFormat from '../../utils/tagFormat';
import { useGetAllTagsApi } from '../../hooks/api/tag.hook';
import {
  CcControlNumber, Document, DocumentSearchDetailForm, SortList,
} from '../../services/http/documents.api';
import { useDocumentSearchDetail } from '../../hooks/api/document.hook';
import { ApiError } from '../../services/http';
import { TagBulk, TagBulkCcControlNumberListItem, TagBulkDocumentListItem } from '../../services/http/tagBulk.api';
import TagBulkAddCard from './tagBulkAddCard';
import AlertModal, {
  alertModalInitialState,
  CloseAlertModal,
  CloseAlertModalProps,
  closeModalInitialState,
} from '../../components/AlertModal';
import LoadingOverlay from '../../components/LoadingOverlay';
import { useGetTagBulkApi } from '../../hooks/api/tagBulk.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import './tagBulkTableStyle.css';
import Breadcrumb from '../../components/Breadcrumb';
import {
  useDocumentMaintenanceBulkChangeRegisterApi,
  useDocumentMaintenanceGetUpdateDateApi,
} from '../../hooks/api/documentsMaintenance.hook';
import CheckModalBody from '../../components/CheckModalBody/checkModalBody';
import {
  BulkChangeRegisterItem,
  BulkChangeRegisterRequest,
  GetUpdateDateResponse,
} from '../../services/http/documentsMaintenance.api';
import style from '../../components/AlertModal/alertModal.module.css';
import BaseModal from '../../components/BaseModal';
import PaginationJp from '../../components/BaseTable/partials/PaginationJp/mannual';
import Formatter from '../../utils/formatters';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { DocumentFields } from '../../utils/documentMaintenance.utils';

interface TagBulkCardItem extends TagBulk {
  isValid: boolean,
}

interface UpdateTagBulkPageForm {
  tagList: TagBulkCardItem[],
  documentList: TagBulkDocumentListItem[],
}

const initialState: UpdateTagBulkPageForm = {
  tagList: [],
  documentList: [],
};

interface DocumentData {
  [key: string]: string;
}

interface CcControlNumberCsv {
  ccControlNumber: string;
}

export interface Pagination {
  page: number,
  totalItems: number,
  pageLimit: number,
  totalPages: number,
}

export const defaultPagination: Pagination = {
  page: 1,
  totalItems: 0,
  pageLimit: 0,
  totalPages: 0,
};

interface ColumnMetaData {
  headerName: string;
  fieldName: string;
  csvFieldName: string;
}

export default function BulkUpdateTagsScreenPage() {
  usePageTitle('一括メンテ実行');

  // hooks
  const navigate = useNavigate();
  const openMessageModal = useMessageModal();

  // API's
  const { allTags, reload: getAllTags, loadingAllTags } = useGetAllTagsApi();
  const { request: documentSearchDetailRequest, loading: documentSearchDetailLoading } = useDocumentSearchDetail();
  const { request: updateBulkChangeRequest, loading: updateBulkChangeLoading } = useDocumentMaintenanceBulkChangeRegisterApi();
  const { request: getTagBulkRequest, loading: getTagBulkLoading } = useGetTagBulkApi();
  const { request: getUpdateDateRequest, data: updateDateData, loading: getUpdateDateLoading } = useDocumentMaintenanceGetUpdateDateApi();
  const { request: createLog } = useCreateLogApi();

  // refs
  const gridRef = useRef<AgGridReact<Document>>(null);
  const inputFile = useRef<HTMLInputElement | null>(null);

  // states
  const [documents, setDocuments] = useState<Document[]>([]);
  const [form, setForm] = useState<UpdateTagBulkPageForm>(initialState);
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [selectTags, setSelectTags] = useState<TagBulk[]>([]);
  const [columnBeingMoved, setColumnBeingMoved] = useState<string | null>(null);
  const [tagAfterColEnd] = useState<string>(`${Date.now()}-after-col-end`);
  const [importedParsedDataState, setImportedParsedDataState] = useState<DocumentData[]>();
  const [fileName, setFileName] = useState<string | null>(null);
  const [closeAlertModal, setCloseAlertModal] = useState<CloseAlertModalProps>(closeModalInitialState);
  const [tenOrFewerItemsModal, setTenOrFewerItemsModal] = useState(false);
  const [serverDocsUpdatedAfterCSVState, setServerDocsUpdatedAfterCSVtate] = useState<string[]>([]);
  const [serverDocsUpdatedBeforeCSVState, setServerDocsUpdatedBeforeCSVState] = useState<CcControlNumberCsv[]>([]);
  const [documentsFromCsvWithoutIdsCount, setDocumentsFromCsvWithoutIdsCount] = useState<number>(0);
  const [filterDocumentsByDateState, setFilterDocumentsByDateState] = useState<boolean>(false);
  const [requestedDocumentsCount, setServerDocsUpdatedBeforeCSVStateCount] = useState<number>(0);
  const [ccControlNumberListState, setCcControlNumberListState] = useState<CcControlNumber[]>([]);
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const [sort, setSort] = useState<SortList[] | undefined>(undefined);
  const [updateTagsFormRequest, setUpdateTagsFormRequest] = useState<BulkChangeRegisterItem[]>([]);

  // constants
  const tagColStart = 'tag-col-';
  const cantGoNext = pagination.page === pagination.totalPages;
  const cantGoPrevious = pagination.page === 1;

  const disableSendButton = useMemo(() => {
    if (form.tagList.length === 0) return true;
    if (form.documentList.length === 0) return true;
    if (form.tagList.some((tag) => !tag.tagLabel)) return true;
    if (form.tagList.some((tag) => !tag.isValid)) return true;
    return false;
  }, [form]);

  const allAvailableTags = useMemo(() => {
    if (selectTags.length === 0) return [];
    const usedTags = new Set<string>(form.tagList.map((tag) => tag.tagLabel));
    return selectTags.filter((tag) => !usedTags.has(tag.tagLabel));
  }, [selectTags, form.tagList]);

  const noAvailableTagsLeft = allAvailableTags.length === 0;

  const onDocumentSelected = useCallback((docs: Document[]) => {
    const documentsIds: TagBulkDocumentListItem[] = docs.map((doc) => ({ documentId: doc.id }));
    setForm((prev) => ({ ...prev, documentList: documentsIds }));
  }, []);

  const handleMoveBeforeColumn = useCallback((columnId: string) => {
    if (!gridRef.current) return;
    const colApi = gridRef.current.columnApi;

    const allCols = colApi.getAllGridColumns();
    if (!allCols) return;

    const colHasAfter = allCols.some((col) => col.getColId() === `${columnId}${tagAfterColEnd}`);
    const allColsWithoutHisAfter = allCols.filter((col) => col.getColId() !== `${columnId}${tagAfterColEnd}`);

    const indexedCols = colHasAfter ? allColsWithoutHisAfter : allCols;
    const beforeColIndex = indexedCols.findIndex((col) => col.getColId() === columnId);
    if (beforeColIndex === -1) return;

    const afterCol = allCols.find((col) => col.getColId() === `${columnId}${tagAfterColEnd}`);
    const afterColIndex = allCols.findIndex((col) => col.getColId() === `${columnId}${tagAfterColEnd}`);
    if (!afterCol || afterColIndex === -1) return;

    colApi.moveColumnByIndex(afterColIndex, beforeColIndex + 1);
  }, [tagAfterColEnd]);

  const handleMoveAfterColumn = useCallback((columnId: string) => {
    if (!gridRef.current) return;
    const colApi = gridRef.current.columnApi;

    const allCols = colApi.getAllGridColumns();
    if (!allCols) return;

    const colIdBefore = columnId.replace(tagAfterColEnd, '');
    const allColsWithoutHisBefore = allCols.filter((col) => col.getColId() !== colIdBefore);

    const afterColIndex = allColsWithoutHisBefore.findIndex((col) => col.getColId() === columnId);
    if (afterColIndex === -1) return;

    const beforeCol = allCols.find((col) => col.getColId() === colIdBefore);
    const beforeColIndex = allCols.findIndex((col) => col.getColId() === colIdBefore);
    if (!beforeCol || beforeColIndex === -1) return;

    colApi.moveColumnByIndex(beforeColIndex, afterColIndex);
  }, [tagAfterColEnd]);

  const moveTagColumns = useCallback((isAddingCol = false) => {
    setTimeout(() => {
      if (!gridRef.current) return;
      const colApi = gridRef.current.columnApi;

      const allCols = colApi.getColumns();
      if (!allCols) return;

      const allBeforeTagCols = allCols.filter((col) => {
        const colId = col.getColId();
        if (!colId.startsWith(tagColStart)) return false;
        if (colId.endsWith(tagAfterColEnd)) return false;
        return true;
      });

      const allAfterTagCols = allCols.filter((col) => {
        const colId = col.getColId();
        if (!colId.startsWith(tagColStart)) return false;
        if (!colId.endsWith(tagAfterColEnd)) return false;
        return true;
      });

      if (!columnBeingMoved || isAddingCol) {
        allBeforeTagCols.forEach((col) => handleMoveBeforeColumn(col.getColId()));
        return;
      }

      const isTagCol = columnBeingMoved.startsWith(tagColStart);
      if (!isTagCol) return;

      const isBeforeCol = columnBeingMoved.startsWith(tagColStart) && !columnBeingMoved.endsWith(tagAfterColEnd);
      if (isBeforeCol) {
        allBeforeTagCols.forEach((col) => handleMoveBeforeColumn(col.getColId()));
        return;
      }

      const isAfterCol = columnBeingMoved.startsWith(tagColStart) && columnBeingMoved.endsWith(tagAfterColEnd);
      if (isAfterCol) {
        allAfterTagCols.forEach((col) => handleMoveAfterColumn(col.getColId()));
      }
    }, 100);
  }, [columnBeingMoved, handleMoveAfterColumn, handleMoveBeforeColumn, tagAfterColEnd, tagColStart]);

  const onAddNewTag = useCallback(() => {
    setForm((prev) => ({
      ...prev,
      tagList: [
        ...prev.tagList,
        {
          id: uuid.v4(),
          tagLabel: '',
          format: TagFormat.STRING,
          required: false,
          value: '',
          isValid: true,
        },
      ],
    }));
    moveTagColumns();
  }, [moveTagColumns]);

  const handleImportFile = useCallback(async () => {
    inputFile.current?.click();
  }, []);

  const extractDocumentIdsAndDates = useCallback((importedDocumentData: DocumentData[]) => importedDocumentData.map((doc) => ({
    ccControlNumber: doc[DocumentFields.CC_CONTROL_NUMBER],
    updateDate: Formatter.fromStringToDateWithFormat(doc[DocumentFields.UPDATE_DATE], Formatter.defaultDateTimeFormat),
  })), []);

  const additionalTagDefinitions = [
    { tagLabel: '文書名', docField: 'name' },
    { tagLabel: '契約種別', docField: 'electronicFlg' },
    { tagLabel: '書面有無', docField: 'paperFlg' },
    { tagLabel: 'バーコード印字欄', docField: 'barcodePrinting' },
    { tagLabel: 'メモ', docField: 'memo' },
  ];

  const tagColIdsOnForm = useMemo(() => {
    if (!form.tagList.length) return [];
    return form.tagList.map((tag) => `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`);
  }, [form.tagList, tagAfterColEnd]);

  const tagColIdsNotOnForm = useMemo(() => {
    if (!allTags.length) return [];

    const tagLabelsOnForm = form.tagList.map((tag) => `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`);
    let allTagLabels = allTags.map((tag) => `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`);

    const additionalTagLabels = additionalTagDefinitions.map(({ tagLabel }) => `${tagColStart}${tagLabel}${tagAfterColEnd}`);
    allTagLabels = [...allTagLabels, ...additionalTagLabels];

    const tags = allTagLabels.filter((tag) => !tagLabelsOnForm.includes(tag));
    return tags;
  }, [allTags, form, tagAfterColEnd, additionalTagDefinitions]);

  const handleColumnVisibleChange = useCallback(() => {
    if (!gridRef.current) return;
    const { columnApi } = gridRef.current;

    columnApi.setColumnsVisible(tagColIdsOnForm, true);
    columnApi.setColumnsVisible(tagColIdsNotOnForm, false);
  }, [tagColIdsOnForm, tagColIdsNotOnForm]);

  const handlePageClear = useCallback(() => {
    setDocuments([]);
    setSelectTags([]);
    setForm(initialState);
    setFileName(null);
    setImportedParsedDataState([]);
    setDocumentsFromCsvWithoutIdsCount(0);
    setFilterDocumentsByDateState(false);
    setServerDocsUpdatedBeforeCSVStateCount(0);
    setCcControlNumberListState([]);
    setUpdateTagsFormRequest([]);
  }, []);

  const openMoreThanTenItemsModal = useCallback((documentsUpdateDate: GetUpdateDateResponse, outdatedDocuments: string[]) => {
    const modalTitle = `選択されたメンテナンス対象${documentsUpdateDate.searchList.length}件のうち \n以下の${outdatedDocuments.length}件は抽出した時点より内容が更新されています。`;
    const modalItems = outdatedDocuments.map((id) => `文書ID: ${id}`);
    const modalFooter = '再度対象データを取得し直してください。';

    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text: <CheckModalBody title={modalTitle} items={modalItems} footer={modalFooter} />,
      onCancel: () => {
        handlePageClear();
        setCloseAlertModal({ ...closeAlertModal, open: false });
      },
    });
  }, [closeAlertModal, handlePageClear]);

  const isDocumentRecentlyUpdated = (targetDocDate: Date, importedDocDate: Date): boolean => new Date(targetDocDate).getTime() > new Date(importedDocDate).getTime();
  const findImportedDocument = (ccControlNumber: string, documentsFromCsvWithDate: { ccControlNumber: string; updateDate: Date }[]) => documentsFromCsvWithDate.find((doc) => doc.ccControlNumber === ccControlNumber);

  const getServerDocsUpdatedAfterCSV = useCallback((documentsUpdateDate: GetUpdateDateResponse, documentsFromCsvWithDate: { ccControlNumber: string; updateDate: Date }[]) => documentsUpdateDate.searchList
    .filter((targetDoc) => {
      const importedDoc = findImportedDocument(targetDoc.ccControlNumber, documentsFromCsvWithDate);

      return importedDoc && targetDoc.updateDate && importedDoc.updateDate
        ? isDocumentRecentlyUpdated(targetDoc.updateDate, importedDoc.updateDate)
        : false;
    })
    .map((doc) => doc.ccControlNumber), []);

  const parseCsv = useCallback((csvContent: string): Promise<DocumentData[]> => new Promise((resolve) => {
    Papa.parse(csvContent, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results: ParseResult<DocumentData>) => {
        resolve(results.data);
      },
    });
  }), []);

  const cleanHeaders = (headers: string[]): string[] => headers.map((header) => (header.startsWith('(変更不可)') ? header.replace('(変更不可)', '') : header));

  const cleanParsedData = useCallback((parsedData: any[]): DocumentData[] => {
    if (parsedData.length === 0) {
      return [];
    }

    const headers = Object.keys(parsedData[0]);
    const cleanedHeaders = cleanHeaders(headers);

    return parsedData.map((row) => {
      const newRow: DocumentData = {};
      cleanedHeaders.forEach((cleanedHeader, index) => {
        const originalHeader = headers[index];
        newRow[cleanedHeader] = row[originalHeader];
      });
      return newRow;
    });
  }, []);

  const parseCsvFile = useCallback((file: File): Promise<DocumentData[]> => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const csvContent = event.target?.result as string;
        const parsedData = await parseCsv(csvContent);
        const cleanedData = cleanParsedData(parsedData);

        resolve(cleanedData);
      } catch (error) {
        reject(new Error('CSVファイルの解析中にエラーが発生しました。'));
      }
    };

    reader.onerror = () => {
      reject(new Error('ファイルの読み込み中にエラーが発生しました。'));
    };

    reader.readAsText(file);
  }), [cleanParsedData, parseCsv]);

  const cleanHeader = (worksheet: XLSX.WorkSheet, range: XLSX.Range) => {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      const cell = worksheet[cellAddress];
      if (cell && cell.t === 's') {
        const header = cell.v as string;
        if (header.startsWith('(変更不可)')) {
          cell.v = cell.v.replace('(変更不可)', '');
          cell.w = cell.w.replace('(変更不可)', '');
          XLSX.utils.format_cell(cell);
        }
      }
    }
  };

  const handleDateColumns = (worksheet: XLSX.WorkSheet, range: XLSX.Range, dateColumnIndex: number) => {
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      const cellAddress = XLSX.utils.encode_cell({ r: R, c: dateColumnIndex });
      const cell = worksheet[cellAddress];
      if (cell && cell.t === 'n') {
        const date = new Date(((cell.v - 25569) * 86400 * 1000) - (9 * 3600 * 1000));
        date.setMilliseconds(0);
        cell.z = Formatter.defaultDateTimeFormat;
        cell.v = date;
        cell.t = 'd';
        cell.w = XLSX.SSF.format(cell.z, cell.v);
        XLSX.utils.format_cell(cell);
      }
    }
  };

  const handleFormattedDateStrings = (worksheet: XLSX.WorkSheet, range: XLSX.Range) => {
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellAddress];
        if (cell && cell.t === 'n' && cell.w) {
          const isDateLike = /\d{1,2}\/\d{1,2}\/\d{2,4}/.test(cell.w) || /\d{4}\/\d{1,2}\/\d{1,2}/.test(cell.w);
          const hasTime = /\d{1,2}:\d{2}(:\d{2})?/.test(cell.w);

          if (isDateLike) {
            const excelDateValue = cell.v as number;
            const date = new Date((excelDateValue - 25569) * 86400 * 1000);

            if (hasTime) {
              cell.z = Formatter.defaultDateTimeFormat;
            } else {
              cell.z = Formatter.defaultDateFormat;
            }

            cell.v = date;
            cell.w = XLSX.SSF.format(cell.z, cell.v);
            XLSX.utils.format_cell(cell);
          }
        }
      }
    }
  };

  const transformXlsxToCsv = useCallback(
    (file: File): Promise<string> => new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const range = XLSX.utils.decode_range(worksheet['!ref'] || '');

          cleanHeader(worksheet, range);

          const headers = XLSX.utils.sheet_to_json<string[]>(worksheet, { header: 1 })[0] as string[];
          const dateColumnIndex = headers.indexOf('更新日時');

          if (dateColumnIndex !== -1) {
            handleDateColumns(worksheet, range, dateColumnIndex);
          }

          handleFormattedDateStrings(worksheet, range);

          const csvContent = XLSX.utils.sheet_to_csv(worksheet);
          resolve(csvContent);
        } catch (error) {
          reject(new Error('ファイルの処理中にエラーが発生しました。'));
        }
      };

      reader.onerror = () => {
        reject(new Error('ファイルの読み込み中にエラーが発生しました。'));
      };

      reader.readAsArrayBuffer(file);
    }),
    [],
  );

  const onConfirmSendForm = useCallback(() => {
    const formattedForm: BulkChangeRegisterRequest = {
      documentList: updateTagsFormRequest,
    };
    try {
      updateBulkChangeRequest(formattedForm);
    } catch (e) {
      console.log(e);
    }
    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text: '変更処理を開始いたしました。\n 変更結果は一括メンテダウンロード画面より確認ください。',
      onCancel: () => {
        handlePageClear();
        setCloseAlertModal({ ...closeAlertModal, open: false });
        window.location.reload();
      },
    });
  }, [closeAlertModal, handlePageClear, updateBulkChangeRequest, updateTagsFormRequest]);

  const getSendForm = useCallback((ccControlNumberList: CcControlNumber[] | undefined) => {
    const sendForm: DocumentSearchDetailForm = {
      fileName: '',
      memo: '',
      name: '',
      ccControlNumberList: ccControlNumberList || ccControlNumberListState,
      tagList: [],
      page: 1,
      bulkTagChange: 1,
    };
    return sendForm;
  }, [ccControlNumberListState]);

  const handleSetPagination = useCallback((res: { total: number; pageLimit: number; page: number; }) => {
    const totalPages = Math.ceil(res.total / res.pageLimit) || 1;
    setPagination({
      page: res.page,
      totalItems: res.total,
      pageLimit: res.pageLimit,
      totalPages,
    });
  }, []);

  const searchDetailDocuments = useCallback(async (page: number, sortList = sort, ccControlNumberList?: CcControlNumber[]) => {
    setSort(sortList);
    const sendForm = getSendForm(ccControlNumberList);

    try {
      if (ccControlNumberListState.length > 0 || ccControlNumberList) {
        const res = await documentSearchDetailRequest({ ...sendForm, page, sortList: sortList || [] });

        setDocuments(res.documents);
        handleSetPagination(res);
        return res;
      }
      return null;
    } catch (e) {
      handlePageClear();
      openMessageModal((e as ApiError)?.message);
      throw e;
    }
  }, [ccControlNumberListState.length, documentSearchDetailRequest, getSendForm, handlePageClear, handleSetPagination, openMessageModal, sort]);

  const getCcControlNumberList = useCallback((filterDocumentsByDate: boolean, documentsUpdateDate?: GetUpdateDateResponse) => {
    let ccControlNumberList;

    if (filterDocumentsByDate) {
      setFilterDocumentsByDateState(true);
      setServerDocsUpdatedBeforeCSVStateCount(serverDocsUpdatedBeforeCSVState?.length || 0);
      ccControlNumberList = serverDocsUpdatedBeforeCSVState.map((doc) => ({ ccControlNumber: Number(doc.ccControlNumber) }));
    } else {
      const currentDocuments = documentsUpdateDate ? documentsUpdateDate.searchList : updateDateData?.searchList;

      setServerDocsUpdatedBeforeCSVStateCount(currentDocuments?.length || 0);
      ccControlNumberList = currentDocuments?.map((doc) => ({ ccControlNumber: Number(doc.ccControlNumber) })) || [];
    }
    setCcControlNumberListState(ccControlNumberList);
    return ccControlNumberList;
  }, [serverDocsUpdatedBeforeCSVState, updateDateData]);

  const getAdditionalTags = useCallback((): TagBulk[] => additionalTagDefinitions.map(({ tagLabel }) => ({
    id: '',
    tagLabel,
    format: TagFormat.STRING,
    required: false,
    value: '',
    count: 1,
  })), [additionalTagDefinitions]);

  const getTagsRequestData = useCallback(async (ccControlNumberIds: TagBulkCcControlNumberListItem[]) => {
    try {
      return await getTagBulkRequest({ ccControlNumberList: ccControlNumberIds });
    } catch (e) {
      handlePageClear();
      openMessageModal((e as ApiError)?.message);
      throw e;
    }
  }, [getTagBulkRequest, handlePageClear, openMessageModal]);

  const getDocumentsAndTags = useCallback(async (filterDocumentsByDate: boolean, documentsUpdateDate?: GetUpdateDateResponse, importedParsedData?: DocumentData[]) => {
    setFilterDocumentsByDateState(filterDocumentsByDate);
    const ccControlNumberList = getCcControlNumberList(filterDocumentsByDate, documentsUpdateDate);

    await searchDetailDocuments(1, [], ccControlNumberList);

    const ccControlNumberIds: TagBulkCcControlNumberListItem[] = ccControlNumberList.map((doc) => ({
      ccControlNumber: doc.ccControlNumber,
    }));

    const getTagsRes = await getTagsRequestData(ccControlNumberIds);

    const parsedData = importedParsedData || importedParsedDataState;

    if (parsedData && parsedData.length > 0 && getTagsRes) {
      const filteredTagsRes = getTagsRes.filter((tag) => tag.tagLabel in parsedData[0]);
      const additionalTags = getAdditionalTags();
      const updatedFilteredTagsRes = [...filteredTagsRes, ...additionalTags];
      setSelectTags(updatedFilteredTagsRes);
    }
  }, [getAdditionalTags, getCcControlNumberList, getTagsRequestData, importedParsedDataState, searchDetailDocuments]);

  const handleSendForm = useCallback(
    () => setAlertModal({
      open: true,
      text: `${ccControlNumberListState.length}件が更新されます。\n`
        + '実施してよろしいですか？',
      onCancel: () => {
        setAlertModal({ ...alertModal, open: false });
      },
      onConfirm: () => {
        onConfirmSendForm();
        setAlertModal({ ...alertModal, open: false });
      },
    }),
    [ccControlNumberListState.length, alertModal, onConfirmSendForm],
  );

  const createColumnDef = (headerName: string, fieldName: string, csvFieldName: string) => ({
    headerName,
    field: `${tagColStart}${fieldName}${tagAfterColEnd}`,
    colId: `${tagColStart}${fieldName}${tagAfterColEnd}`,
    flex: 1,
    minWidth: 150,
    resizable: true,
    headerClass: styles.tagHeaderOnForm,
    cellClass: 'tagCellOnForm',
    initialHide: true,
    valueGetter: (params: any) => {
      const fieldValue = params.node?.data[fieldName];
      const csvDataEntry = importedParsedDataState?.find(
        (data) => data[DocumentFields.CC_CONTROL_NUMBER].toString() === params.node?.data.ccControlNumber.toString(),
      );
      const csvTagValue = csvDataEntry ? csvDataEntry[csvFieldName] : undefined;
      return csvTagValue || fieldValue?.value;
    },
  });

  const columnMetaData: Record<string, ColumnMetaData> = additionalTagDefinitions.reduce(
    (acc, { tagLabel, docField }) => {
      // eslint-disable-next-line no-param-reassign
      acc[docField] = {
        headerName: `${tagLabel}(変更後)`,
        fieldName: tagLabel,
        csvFieldName: tagLabel,
      };
      return acc;
    },
    {} as Record<string, ColumnMetaData>,
  );

  const colsDefs = useMemo((): ColDef[] => {
    const cols: ColDef[] = [
      AGUtils.colDefault('ccControlNumber', '文書ID'),
      {
        headerName: '文書名',
        resizable: true,
        sortable: true,
        field: `${tagColStart}文書名`,
        width: 250,
        valueGetter: (params) => params.node?.data.name,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams) => (
          <div
            className={mainStyles['ml-3']}
            style={{ alignContent: 'center', display: 'flex' }}
          >
            {params.node.data.fileName
              ? (
                <img
                  src="/images/Pdf-icon.svg"
                  alt="pdf"
                />
              )
              : (
                <img
                  src="/images/Pdf-icon-semitransparent.svg"
                  alt="pdf"
                />
              )}
            <span className={[mainStyles['ml-2'], mainStyles['text-overflow'], 'highlightTarget'].join(' ')}>
              {params.value}
            </span>
            {params.node.data.requiredTagInputFlg !== 1 && (
              <div style={{ marginLeft: 'auto' }}>
                <img src="/images/Icon-awesome-exclamation.svg" alt="exclamation" style={{ height: '12px', marginRight: '5px' }} />
              </div>
            )}
          </div>
        ),
      },
      AGUtils.colDefault('typeName', '文書種類'),
      AGUtils.colDefault('fileName', 'ファイル名', 170, true, true),
      AGUtils.colUsername('registUser', '登録者'),
      AGUtils.colDate('registDate', '登録日時'),
      AGUtils.colDefault('itemCode', '保管品バーコード'),
      {
        ...AGUtils.colDefault(`${tagColStart}書面有無`, '書面有無'),
        valueGetter: (params) => params.node?.data.paperFlg,
      },
      {
        ...AGUtils.colDefault(`${tagColStart}契約種別`, '契約種別'),
        valueGetter: (params) => params.node?.data.electronicFlg,
      },
      {
        ...AGUtils.colDefault(`${tagColStart}バーコード印字欄`, 'バーコード印字欄'),
        valueGetter: (params) => params.node?.data.barcodePrinting,
      },
      {
        ...AGUtils.colDefault(`${tagColStart}メモ`, 'メモ'),
        valueGetter: (params) => params.node?.data.memo,
      },
      ...Object.keys(columnMetaData).map((key) => {
        const { headerName, fieldName, csvFieldName } = columnMetaData[key];
        return createColumnDef(headerName, fieldName, csvFieldName);
      }),
    ];

    const tags = new Set<string>(allTags.map((tag) => tag.tagLabel));
    const unique = Array.from(tags);

    unique.forEach((tag) => {
      const isTagOnForm = !!form.tagList.find((t) => t.tagLabel === tag);
      const beforeCol: ColDef = {
        colId: `${tagColStart}${tag}`,
        field: `${tagColStart}${tag}`,
        headerName: tag,
        resizable: true,
        sortable: true,
        flex: 1,
        headerClass: isTagOnForm ? styles.tagHeaderOnForm : '',
        minWidth: 160,
        valueGetter: ({ data }) => {
          const docTag = data.tagList.find((t: { label: string, value: string }) => t.label === tag);
          if (docTag) {
            return docTag.value;
          }
          return '';
        },
      };
      const afterTagCol: ColDef = {
        headerName: `${tag}(変更後)`,
        field: `${tagColStart}${tag}${tagAfterColEnd}`,
        colId: `${tagColStart}${tag}${tagAfterColEnd}`,
        flex: 1,
        minWidth: 150,
        resizable: true,
        headerClass: styles.tagHeaderOnForm,
        cellClass: 'tagCellOnForm',
        initialHide: true,
        valueGetter: (params) => {
          const nodeTagValue = params.node?.data.tagList.find((t: { label: string; value: string }) => t.label === tag);
          if (nodeTagValue) {
            const csvDataEntry = importedParsedDataState?.find((data) => data[DocumentFields.CC_CONTROL_NUMBER].toString() === params.node?.data.ccControlNumber.toString());
            const csvTagValue = csvDataEntry ? csvDataEntry[tag] : undefined;

            return csvTagValue || nodeTagValue.value;
          }

          return '';
        },
      };
      cols.push(beforeCol);
      cols.push(afterTagCol);
    });

    return cols;
  }, [allTags, form.tagList, importedParsedDataState, tagAfterColEnd]);

  const getUpdateDateRequestData = useCallback(async (documentsIds: CcControlNumberCsv[]) => {
    try {
      return await getUpdateDateRequest({ searchList: documentsIds });
    } catch (e) {
      handlePageClear();
      openMessageModal((e as ApiError)?.message);
      throw e;
    }
  }, [getUpdateDateRequest, handlePageClear, openMessageModal]);

  const handleDocumentsAndModals = useCallback(async (importedParsedData: DocumentData[]) => {
    const documentsIds: CcControlNumberCsv[] = importedParsedData.map((doc) => ({ ccControlNumber: doc[DocumentFields.CC_CONTROL_NUMBER] }));

    const documentsUpdateDate = await getUpdateDateRequestData(documentsIds);
    const documentsFromCsvWithDate = extractDocumentIdsAndDates(importedParsedData);

    const serverDocsUpdatedAfterCSV = getServerDocsUpdatedAfterCSV(documentsUpdateDate, documentsFromCsvWithDate);
    const serverDocsUpdatedBeforeCSV = documentsUpdateDate.searchList.filter((doc) => !serverDocsUpdatedAfterCSV.includes(doc.ccControlNumber));

    setDocumentsFromCsvWithoutIdsCount(documentsFromCsvWithDate.length - documentsUpdateDate.searchList.length);
    setServerDocsUpdatedBeforeCSVState(serverDocsUpdatedBeforeCSV);
    setServerDocsUpdatedAfterCSVtate(serverDocsUpdatedAfterCSV);

    if (serverDocsUpdatedAfterCSV.length > 10) {
      openMoreThanTenItemsModal(documentsUpdateDate, serverDocsUpdatedAfterCSV);
    } else if (serverDocsUpdatedAfterCSV.length >= 1 && serverDocsUpdatedAfterCSV.length <= 10) {
      setTenOrFewerItemsModal(true);
    } else {
      getDocumentsAndTags(false, documentsUpdateDate, importedParsedData);
    }
  }, [extractDocumentIdsAndDates, getDocumentsAndTags, getServerDocsUpdatedAfterCSV, getUpdateDateRequestData, openMoreThanTenItemsModal]);

  const processParsedData = useCallback((importedParsedData: DocumentData[], csvFileName: string) => {
    const isMissingRequiredColumns = importedParsedData.length === 0
        || !('文書ID' in importedParsedData[0])
        || !('更新日時' in importedParsedData[0]);

    if (isMissingRequiredColumns) {
      openMessageModal('一括メンテナンスを行うためには対象ファイルに \n「文書ID」、「更新日時」の項目が含まれているファイルが必要です。');
      handlePageClear();
    } else {
      const importedParsedDataFiltered = importedParsedData.filter((row) => row[DocumentFields.CC_CONTROL_NUMBER]);
      setDocumentsFromCsvWithoutIdsCount(importedParsedData.length - importedParsedDataFiltered.length);
      setFileName(csvFileName);
      setImportedParsedDataState(importedParsedData);
      handleDocumentsAndModals(importedParsedDataFiltered);
    }
  }, [openMessageModal, handlePageClear, handleDocumentsAndModals]);

  const handleFileProcessing = useCallback(async (file: File) => {
    try {
      let parsedData;

      if (file.name.endsWith('.csv')) {
        parsedData = await parseCsvFile(file);
      } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
        const csvContent = await transformXlsxToCsv(file);
        parsedData = await parseCsv(csvContent);
      } else {
        openMessageModal('対応していないファイル形式です。');
        return;
      }
      processParsedData(parsedData, file.name);
    } catch (error) {
      openMessageModal('ファイルの処理中にエラーが発生しました。');
      handlePageClear();
    }
  }, [processParsedData, parseCsvFile, transformXlsxToCsv, parseCsv, openMessageModal, handlePageClear]);

  const handleFileSelection = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    handlePageClear();
    const file = event.target.files?.[0];
    if (file) {
      await handleFileProcessing(file);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  }, [handleFileProcessing, handlePageClear]);

  const getDocumentList = useCallback((tag: TagBulk) => ccControlNumberListState.map((doc): BulkChangeRegisterItem | null => {
    const additionalTagDefinition = additionalTagDefinitions.find((def) => def.tagLabel === tag.tagLabel);
    const csvData = importedParsedDataState?.find((csv) => csv[DocumentFields.CC_CONTROL_NUMBER] === doc.ccControlNumber.toString());

    if (csvData) {
      return {
        ccControlNumber: doc.ccControlNumber,
        tagLabel: additionalTagDefinition ? additionalTagDefinition.tagLabel : tag.tagLabel,
        format: tag.format,
        value: csvData[tag.tagLabel] || '',
      };
    }
    return null;
  }).filter((item): item is BulkChangeRegisterItem => item !== null), [additionalTagDefinitions, ccControlNumberListState, importedParsedDataState]);

  const handleSelectTag = useCallback((id: string, tag: TagBulk | null) => {
    if (!tag) {
      setForm((prev) => ({
        ...prev,
        tagList: prev.tagList.map((t) => (t.id === id ? {
          id: t.id,
          tagLabel: '',
          format: TagFormat.STRING,
          required: false,
          value: '',
          isValid: true,
        } : t)),
      }));
      return;
    }

    const documentList = getDocumentList(tag);

    setUpdateTagsFormRequest((prev: BulkChangeRegisterItem[]) => [...prev, ...documentList]);

    setForm((prev) => ({
      ...prev,
      tagList: prev.tagList.map((t) => (t.id === id ? {
        ...t, tagLabel: tag.tagLabel, format: tag.format, required: tag.required, value: '', type: 'タグ',
      } : t)),
    }));

    setTimeout(() => {
      if (!gridRef.current) return;
      const colId = `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`;
      gridRef.current.api.ensureColumnVisible(colId, 'end');
    }, 50);
  }, [getDocumentList, tagAfterColEnd]);

  const handleDeleteTag = useCallback((tag: TagBulk) => {
    const isAdditionalTag = additionalTagDefinitions.find(
      (definition) => definition.tagLabel === tag.tagLabel,
    );

    if (isAdditionalTag) {
      setUpdateTagsFormRequest((prev) => prev.filter((item) => item.tagLabel !== isAdditionalTag.docField));
    } else {
      // Otherwise, filter using taLabel as usual
      setUpdateTagsFormRequest((prev) => prev.filter((item) => item.tagLabel !== tag.tagLabel));
    }

    setUpdateTagsFormRequest((prev) => prev.filter((item) => item.tagLabel !== tag.tagLabel));

    setForm((prev) => ({
      ...prev,
      tagList: prev.tagList.filter((_tag) => _tag.id !== tag.id),
    }));
  }, [additionalTagDefinitions]);

  const onSortChanged = useCallback((sortList: SortList[]) => {
    let result = sortList;

    if (sortList.length > 0) {
      const { order } = sortList[0];
      let { item } = sortList[0];
      item = item === 'name' ? 'documentName' : item;
      result = [{ item, order }];
    }

    if (result.length === 0) return;

    setSort(result);
    if (pagination.totalPages !== 1) {
      searchDetailDocuments(pagination.page, result);
    }
  }, [pagination.page, pagination.totalPages, searchDetailDocuments]);

  // effects
  useEffect(() => {
    getAllTags();
  }, [getAllTags]);

  useEffectOnce(() => {
    createLog(LogFormName.BulkMaintenance, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[
            { label: '寺田倉庫用', route: routes.bpoMenuScreen },
            { label: document.title }]}
          />

          <div className={styles['mainframe-body']}>
            <div className={styles.upperCard}>
              <div className={styles.row}>
                <div className={[styles.firstColumn, styles.labelColumn].join(' ')}>
                  <label>一括メンテファイル</label>
                </div>
              </div>

              <div className={styles.row}>
                <div className={[styles.secondColumn, styles.column, mainStyles['text-overflow-ellipsis']].join(' ')}>
                  {fileName}
                </div>

                <div className={styles.column}>
                  <Button
                    color="primary"
                    size="smaller"
                    onClick={() => {
                      handleImportFile();
                    }}
                  >
                    読込
                  </Button>
                </div>
                <div className={styles.column}>
                  <Button color="lighterGray" size="smaller" onClick={() => handlePageClear()}>リセット</Button>
                </div>
              </div>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.documentsInfo}>
              <div style={{ marginRight: '120px' }}>
                対象件数
                {' '}
                {requestedDocumentsCount}
                件
              </div>
              <div className={mainStyles['mr-20px']}>
                CSV読込み件数
                {' '}
                {importedParsedDataState?.length || 0}
                {' '}
                件
              </div>
              {documentsFromCsvWithoutIdsCount > 0 && (
              <div className={mainStyles['mr-20px']}>
                文書IDなし：-
                {documentsFromCsvWithoutIdsCount}
                件
              </div>
              )}
              {filterDocumentsByDateState && (
                <div className={mainStyles['mr-20px']}>
                  更新日付による除外：-
                  {serverDocsUpdatedAfterCSVState.length}
                  件
                </div>
              )}
            </div>
            <div className={styles.mainCard}>
              <div className={styles['table-container']}>
                <div className={styles.tableWrapper}>
                  <BaseTable<Document>
                    formName="tagBulkScreenTable"
                    waitToLoad={loadingAllTags}
                    tableRef={gridRef}
                    rowData={documents}
                    columnDefs={colsDefs}
                    rowSelection="multiple"
                    noRowsText={`読み込みボタンを押下し、メンテナンス対象のファイルを選択してください。\n
                    タグの値の変更は検索結果のうちチェックされたタグのみが対象となります。`}
                    suppressRowClickSelection
                    suppressCellFocus
                    useColPin
                    pagination
                    paginationPageSize={500}
                    suppressPaginationPanel
                    masterDetail
                    embedFullWidthRows
                    suppressMenuHide={false}
                    onSelectionChanged={(e) => onDocumentSelected(e.api.getSelectedRows())}
                    rowClass="tagBulkRow"
                    onRowDataUpdated={(e) => e.api.selectAll()}
                    onColumnMoved={(e) => {
                      if (!e.column) return;
                      const columnId = e.column.getColId();
                      if (!columnId.startsWith(tagColStart)) {
                        setColumnBeingMoved(null);
                        return;
                      }
                      setColumnBeingMoved(columnId);
                    }}
                    onDragStopped={() => {
                      moveTagColumns();
                      setColumnBeingMoved(null);
                    }}
                    onNewColumnsLoaded={() => handleColumnVisibleChange()}
                    sortChangedHandler={onSortChanged}
                  />
                </div>
                <div className={styles.paginationLimitContainer}>
                  <PaginationJp
                    totalPage={pagination.totalPages}
                    pageInfo={{ pageLimit: pagination.pageLimit, page: pagination.page, total: pagination.totalItems }}
                    onBtFirst={() => {
                      if (cantGoPrevious) return;
                      searchDetailDocuments(1);
                    }}
                    onBtLast={() => {
                      if (cantGoNext) return;
                      searchDetailDocuments(pagination.totalPages);
                    }}
                    onBtNext={() => {
                      if (cantGoNext) return;
                      searchDetailDocuments(pagination.page + 1);
                    }}
                    onBtPrevious={() => {
                      if (cantGoPrevious) return;
                      searchDetailDocuments(pagination.page - 1);
                    }}
                  />
                </div>
              </div>

              <div className={styles.sideCard}>
                <label className={styles.sideCardLabel}>値を上書きする項目</label>
                {form.tagList.map((tag) => (
                  <TagBulkAddCard
                    key={tag.id}
                    tag={tag}
                    allTags={allAvailableTags}
                    onDeleteTag={() => handleDeleteTag(tag)}
                    onInputFocus={() => {
                      if (!gridRef.current) return;
                      const colId = `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`;
                      gridRef.current.api.ensureColumnVisible(colId, 'end');
                    }}
                    onSelectTag={(val) => handleSelectTag(tag.id, val)}
                    type={tag.type}
                    selectLabel="変更対象"
                    typeLabel="項目種別"
                  />
                ))}

                <Button style={{ minHeight: '30px' }} disabled={noAvailableTagsLeft} color="lighterGray" size="smaller" onClick={() => onAddNewTag()}>変更対象の追加</Button>
              </div>

            </div>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleFileSelection}
              accept=".csv, .xlsx"
            />
          </div>
          <footer className={styles['body-footer']}>
            <Button color="lighterGray" size="smaller" onClick={() => navigate(routes.bpoMenuScreen)}>キャンセル</Button>
            <Button size="smaller" onClick={handleSendForm} disabled={disableSendButton}>変更登録</Button>
          </footer>
          <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
          <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />

          <BaseModal open={tenOrFewerItemsModal} style={{ width: '530px' }}>
            <div className={[styles.alertMessage].join(' ')}>
              選択されたメンテナンス対象
              {updateDateData?.searchList.length}
              件のうち
              <br />
              以下の
              {serverDocsUpdatedAfterCSVState.length}
              件は抽出した時点より内容が更新されています。
              <br />
              {serverDocsUpdatedAfterCSVState.slice(0, 5).map((item) => (
                <div key={uuid.v1()} className={mainStyles['text-overflow-ellipsis']}>
                  -
                  {' '}
                  文書ID:
                  {' '}
                  {item}
                </div>
              ))}
              {serverDocsUpdatedAfterCSVState.length > 5 && (
              <div>
                他
                {serverDocsUpdatedAfterCSVState.length - 5}
                件
              </div>
              )}
            </div>
            <div className={style.alertButtons} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                loading={getUpdateDateLoading} // change this loading
                size="smaller"
                onClick={() => {
                  getDocumentsAndTags(true);
                  setTenOrFewerItemsModal(false);
                }}
              >
                除外する
              </Button>
              <Button
                loading={getUpdateDateLoading} // change this loading
                size="smaller"
                onClick={() => {
                  getDocumentsAndTags(false);
                  setTenOrFewerItemsModal(false);
                }}
              >
                除外しない
              </Button>
              <Button
                color="lightGray"
                size="smaller"
                onClick={() => {
                  handlePageClear();
                  setTenOrFewerItemsModal(false);
                }}
              >
                キャンセル
              </Button>
            </div>
          </BaseModal>
          {(getUpdateDateLoading || documentSearchDetailLoading || getTagBulkLoading) ? <LoadingOverlay /> : null}
        </div>
      )}
    />
  );
}
