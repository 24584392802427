import React, {
  FocusEvent, KeyboardEventHandler, RefObject, useRef, useEffect,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import TextareaAutosize from '@mui/base/TextareaAutosize';
import styles from './inputAutoSize.module.css';
import mainStyles from '../../pages/main.module.css';

export interface InputProps {
  className?: string,
  value?: string
  maxLength?: number,
  disabled?: boolean,
  placeholder?: string,
  inputRef?: RefObject<HTMLTextAreaElement>
  readOnly?: boolean,
  onChange?: (e: string) => void
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
  onClick?: () => void,
  onFocus?: () => void,
  style?: React.CSSProperties,
  title?: string,
}

function Input(props: InputProps) {
  const {
    className, value, inputRef, maxLength, placeholder, readOnly, disabled, onChange = () => ({}), onBlur = () => ({}), onClick = () => ({}), onFocus = () => ({}), onKeyPress, onKeyDown, style, title,
  } = props;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef && 'current' in inputRef) {
      (inputRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = textAreaRef.current;
    }
  }, [inputRef]);

  return (
    <TextareaAutosize
      className={[mainStyles.textareaAutosize, className, disabled && styles.disabled].join(' ')}
      value={value || ''}
      ref={textAreaRef}
      maxLength={maxLength}
      readOnly={readOnly}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      style={style}
      title={title}
    />
  );
}

export default Input;
