import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
  ColumnState, ExcelDataType, PostSortRowsParams, RowNode, SideBarDef,
} from 'ag-grid-enterprise';
import { ColumnMovedEvent, ColumnResizedEvent } from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './baseTable.css';
import { useGetColumnSettingsApi, useSetColumnSettingsApi } from '../../hooks/api/gridControl.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import { Order, SortList } from '../../services/http/documents.api';
import CustomToolPanel, { Icons } from './CustomToolPanel';
import { STATUS_MESSAGES } from '../../utils/messages';
import useExportAndLog from '../../hooks/useExportAndLog.hook';

interface BaseTableProps<T> extends AgGridReactProps<T> {
  tableRef?: React.RefObject<AgGridReact<T>>,
  minWidthProp?: string,
  formName?: string,
  externalFormName?: string,
  waitToLoad?: boolean,
  noRowsText?: string,
  // This flag is used to determine whether the column settings 'pinned' should be saved or not.
  useColPin?: boolean,
  sortChangedHandler?: (sortList: SortList[]) => void,
  handleSortedData?: (sortedData: T[]) => void,
  sortFunction?: (nodes: RowNode<any>[]) => void,
  onColumnMoved?: (e: ColumnMovedEvent<T>) => void,
  onColumnResized?: (e: ColumnResizedEvent<T>) => void,
  createLogOnDownloadTableData?: string,
}

const isTheSame = (a: ColumnState[], b: ColumnState[]):boolean => {
  if (a.length !== b.length) {
    return false;
  }

  const aJson = JSON.stringify(a);
  const bJson = JSON.stringify(b);

  return aJson === bJson;
};

enum ColumnSettingsStatus {
  COLUMN_SETTINGS_WAITING_INITILIZATION = 'COLUMN_SETTINGS_WAITING_INITILIZATION',
}

function BaseTable<T=never>(props: BaseTableProps<T>) {
  const {
    tableRef,
    minWidthProp,
    formName,
    externalFormName,
    waitToLoad,
    noRowsText = STATUS_MESSAGES.NO_DATA,
    useColPin = false,
    sortChangedHandler,
    handleSortedData,
    onColumnMoved = () => ({}),
    onColumnResized = () => ({}),
    sortFunction,
    createLogOnDownloadTableData: logFormName,
    ...rest
  } = props;

  const [columnsSettings, setColumnsSettings] = useState<ColumnState[] | null | undefined | ColumnSettingsStatus>(ColumnSettingsStatus.COLUMN_SETTINGS_WAITING_INITILIZATION);

  const { request: getColumnSettingsRequest } = useGetColumnSettingsApi();
  const { request: setColumnSettingsRequest } = useSetColumnSettingsApi();
  const { getContextMenuItems } = useExportAndLog({ tableRef, logFormName });

  const openMessageModal = useMessageModal();

  const shouldRequestSetColumns = (data: ColumnState[] | null | undefined | ColumnSettingsStatus, colState: ColumnState[]): boolean => {
    // not initialized
    if (data === ColumnSettingsStatus.COLUMN_SETTINGS_WAITING_INITILIZATION) {
      return false;
    }

    // server return with the value of null object.
    if (data == null) {
      return true;
    }

    if (isTheSame(data, colState)) {
      return false;
    }

    if (waitToLoad) {
      return false;
    }

    return true;
  };

  const onRestoreColState = useCallback(async () => {
    try {
      // fetch columns settings info from server
      if (!formName && !externalFormName) return;
      let tableName = '';
      if (externalFormName) { tableName = externalFormName; }
      if (formName) { tableName = formName; }
      const restoredColState = await getColumnSettingsRequest({ formName: tableName });

      if (restoredColState.settingJson == null) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        debouncedSaveColState();
        setColumnsSettings(null);
        return;
      }

      if (!waitToLoad) {
        try {
          // parse info
          const existedSettingsData = restoredColState.settingJson.map((c) => ({ ...c, pinned: useColPin ? c.pinned : null }));
          // update react state
          setColumnsSettings(existedSettingsData);
          // adapte the settings info to ag-grid table
          tableRef?.current?.columnApi.applyColumnState({ state: existedSettingsData, defaultState: { width: undefined }, applyOrder: true });
        } catch (e) {
          console.error(e);
        }
        return;
      }
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [formName, getColumnSettingsRequest, openMessageModal, tableRef, waitToLoad, useColPin, externalFormName]);

  const onSaveColState = useCallback(async () => {
    if (!formName) return;
    const colState = tableRef?.current?.columnApi.getColumnState();
    if (colState == null) {
      return;
    }

    if (!shouldRequestSetColumns(columnsSettings, colState)) {
      return;
    }

    if (!waitToLoad) {
      try {
        await setColumnSettingsRequest({ formName, settingJson: colState });
        setColumnsSettings(colState);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [formName, setColumnSettingsRequest, openMessageModal, columnsSettings, tableRef, onRestoreColState, waitToLoad]);

  const debounce = (func: () => void, wait: number) => {
    let timeout: NodeJS.Timeout;

    return function executedFunction() {
      const later = () => {
        clearTimeout(timeout);
        func();
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debouncedSaveColState = useMemo(() => debounce(onSaveColState, 1000), [onSaveColState]);

  const excelStyles = useMemo(() => [
    {
      id: 'textFormat',
      dataType: 'String' as ExcelDataType,
    },
  ], []);

  useEffect(() => {
    if (columnsSettings !== ColumnSettingsStatus.COLUMN_SETTINGS_WAITING_INITILIZATION && (columnsSettings as ColumnState[])) {
      tableRef?.current?.columnApi.applyColumnState({ state: (columnsSettings as ColumnState[]), defaultState: { width: undefined }, applyOrder: true });
    }
  }, [columnsSettings, rest.columnDefs, tableRef]);

  const resetColState = useCallback(() => tableRef?.current?.columnApi.resetColumnState(), [tableRef]);

  const sideBar = useMemo<
  SideBarDef
  >(() => ({
    toolPanels: [
      {
        id: 'columns',
        labelDefault: '',
        labelKey: 'columns',
        iconKey: 'filter',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressValues: true,
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
      {
        id: 'customStats',
        labelDefault: '',
        labelKey: 'customStats',
        iconKey: 'custom-stats',
        toolPanel: () => CustomToolPanel({ onReset: resetColState }),
      },
    ],
  }), []);
  // const columnDefs = useMemo(() => props.columnDefs?.map((item) => ({ ...item, cellStyle: {...item.cellStyle, color: "green"}})), [props.columnDefs]); // eslint-disable-line

  useEffect(() => {
    onRestoreColState();
  }, [onRestoreColState]);

  const handleOnSortChanged = () => {
    debouncedSaveColState();

    const colState = tableRef?.current?.columnApi.getColumnState();
    if (colState == null) {
      return;
    }

    const sorted = colState.filter((col) => col.sort)
      .map((col) => ({
        item: col.colId,
        order: col.sort?.toUpperCase() === 'ASC' ? Order.ASC : Order.DESC,
      } as SortList));

    sortChangedHandler?.(sorted);
  };

  const handleOnColumnsChanges = () => {
    debouncedSaveColState();
  };

  const handleOnColumnMoved = (e: ColumnMovedEvent<T>) => {
    onColumnMoved(e);
    debouncedSaveColState();
  };

  const handleOnSort = (params: PostSortRowsParams) => {
    const newSortedData = params.nodes.map((node) => node.data);
    if (handleSortedData) handleSortedData(newSortedData);

    if (sortFunction) {
      sortFunction(params.nodes);
    }

    if (!tableRef || !tableRef.current) return;
    tableRef.current.api.refreshCells();
  };

  const handleOnColumnResized = (e: ColumnResizedEvent<T>) => {
    onColumnResized(e);
    debouncedSaveColState();
  };

  useEffect(() => {
    if (tableRef && tableRef.current && tableRef.current.api) {
      tableRef.current.api.showNoRowsOverlay();
    }
  }, [noRowsText, tableRef]);

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', minWidth: minWidthProp }}>
      <AgGridReact<T>
        getContextMenuItems={getContextMenuItems}
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        onSortChanged={handleOnSortChanged}
        onGridColumnsChanged={handleOnColumnsChanges}
        onColumnMoved={(e) => handleOnColumnMoved(e)}
        ref={tableRef}
        excelStyles={excelStyles}
        headerHeight={rest.headerHeight ?? 42}
        groupHeaderHeight={rest.groupHeaderHeight ?? 42}
        suppressRowTransform
        icons={Icons}
        sideBar={rest.sideBar ? sideBar : false}
        defaultColDef={{ suppressMenu: true, lockPinned: true }}
        suppressDragLeaveHidesColumns
        overlayNoRowsTemplate={noRowsText}
        postSortRows={handleOnSort}
        onColumnResized={handleOnColumnResized}
        suppressFieldDotNotation
      />
    </div>
  );
}

export default BaseTable;
