import axios from 'axios';
import FileUtils from '../../utils/file.utils';

export interface SearchResultItem {
  searchKey: string;
  searchStatus?: number;
  searchResult?: number;
  searchResultNumber?: number;
  errorReason?: string;
  downloadCount?: number;
  registUser: string;
  registDate: string;
  updateUser: string;
  updateDate: string;
}

export interface BulkChangeItem {
  changeKey: string;
  changeStatus?: number;
  changeResult?: number;
  errorReason?: string;
  downloadCount?: number;
  registUser: string;
  registDate: string;
  updateUser: string;
  updateDate: string;
}

export interface SearchListResponse {
  searchList: SearchResultItem[];
}

export interface BulkChangeResponse {
  changeList: BulkChangeItem[];
}

export interface SearchCondition {
  searchName: string;
  searchValue: string[];
}

export interface SearchRegisterForm {
  searchList: SearchCondition[];
}

export interface GetUpdateDateRequest {
  searchList: {
    ccControlNumber: string;
  }[];
}

export interface GetUpdateDateResponse {
  searchList: {
    ccControlNumber: string;
    updateDate: Date | null,
  }[];
}

export interface BulkChangeRegisterRequest {
  documentList: BulkChangeRegisterItem[];
}

export interface BulkChangeRegisterItem {
  ccControlNumber: number;
  tagLabel: string;
  format: number;
  value: string;
}

export default {
  /**
   * 一括検索結果一覧取得を実行します。
   *
   * @returns - 検索結果の一覧
   */
  async getSearchList(): Promise<SearchResultItem[]> {
    const res = await axios.get<SearchListResponse>('/DocumentsMaintenance/getSearchList');
    return res.data.searchList;
  },

  /**
   * 一括メンテ結果一覧取得を実行します。
   * @returns - 変更結果の一覧
   */
  async getBulkChangeList(): Promise<BulkChangeItem[]> {
    const res = await axios.get<BulkChangeResponse>('/DocumentsMaintenance/getBulkChangeList');
    return res.data.changeList;
  },

  /**
     * 一括検索登録を実行します。
     *
     * @param form - 検索登録フォーム
     * @returns - 空のPromise
     */
  async searchRegister(form: SearchRegisterForm): Promise<void> {
    const res = await axios.post('/DocumentsMaintenance/searchRegister', form);
    return res.data;
  },

  /**
   * 一括検索結果ファイルダウンロードを実行します。
   *
   * @param searchKey - 検索キー
   * @returns - ダウンロードファイルのバイナリデータ
   */
  async downloadSearchResultFile(searchKey: string): Promise<File> {
    const res = await axios.post('/DocumentsMaintenance/downloadSearchResultFile', { searchKey });
    return FileUtils.fromBase64(`${res.data.downloadSearchResultFile}`, `一括メンテ検索結果_${res.data.message}_処理番号_${searchKey}.zip`);
  },

  /**
   * 一括メンテエラーファイルダウンロードを実行します。
   * @param changeKey - 変更キー
   * @returns - ダウンロードファイルのバイナリデータ
   */
  async downloadBulkChangeErrorFile(changeKey: string): Promise<File> {
    const res = await axios.post('/DocumentsMaintenance/downloadBulkChangeErrorFile', { changeKey });
    return FileUtils.fromBase64(`${res.data.downloadBulkChangeErrorFile}`, `一括メンテ結果_${res.data.message}_処理番号_${changeKey}.zip`);
  },

  /**
   * 更新日時取得を実行します。
   * @param request - リクエストデータ
   * @returns - 更新日時取得結果
   */
  async getUpdateDate(request: GetUpdateDateRequest): Promise<GetUpdateDateResponse> {
    const res = await axios.post<GetUpdateDateResponse>('/DocumentsMaintenance/getUpdateDate', request);
    return res.data;
  },

  /* 一括メンテ登録を実行します。
  * @param documentList - 文書リスト
  * @returns - 空のPromise
  */
  async bulkChangeRegister(form: BulkChangeRegisterRequest): Promise<void> {
    const res = await axios.post('/DocumentsMaintenance/bulkChangeRegister', form);
    return res.data;
  },
};
