import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './tagSearchResultsDownloadPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import Breadcrumb from '../../components/Breadcrumb';
import BaseTable from '../../components/BaseTable';
import Button from '../../components/Button/button';
import LoadingOverlay from '../../components/LoadingOverlay';
import AGUtils from '../../utils/ag-grid.utils';
import BaseText from '../../components/BaseText/BaseText';
import BulkTagRegisterComponent from './BulkTagRegisterComponent';
import DropdownMenu from '../../components/DropdownMenu';
import AlertModal, { alertModalInitialState, CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import { useMessageModal } from '../../hooks/modal.hook';
import {
  useDocumentMaintenanceGetSearchListApi,
  useDocumentMaintenanceSearchApi, useDownloadSearchResultFileApi,
} from '../../hooks/api/documentsMaintenance.hook';
import { SearchResultItem } from '../../services/http/documentsMaintenance.api';
import { useDownloadFile } from '../../hooks/file.hook';
import { SearchResult, SearchStatus } from '../../utils/documentMaintenance.utils';
import routes from '../../utils/routes';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * セル'ボタン'のコンポーネントインターフェース
 */
export interface Props {
  /** テーブルセルレンダリングされるログデータ */
  data: SearchResultItem;
  /** "詳細" ボタンがクリックされた時に実行されるハンドラー */
  onDownloadClick: (data: SearchResultItem) => void;
  /** "リロード" ボタンがクリックされた時に実行されるハンドラー */
  onReloadClick: () => void;
}

export function TableBtnCellRender({
  data, onDownloadClick, onReloadClick,
}: Props) {
  const disableButton = data.searchStatus === 0;
  const buttonText = disableButton ? '処理中' : 'ダウンロード';

  return (
    <div className={styles.importButtonContainer}>
      <div className={styles.refreshContainer}>
        {
          (disableButton && (
          <img
            style={{ marginLeft: '-15px' }}
            onClick={onReloadClick}
            alt="reload"
            src="/images/Reload.svg"
            className={styles.refreshButton}
          />
          ))
        }
      </div>
      <button
        className={[disableButton ? styles.tableButtonDisabled : styles.tableButton, mainStyles['btn-gray']].join(' ')}
        disabled={disableButton}
        type="button"
        onClick={() => onDownloadClick(data)}
      >
        { buttonText }
      </button>
    </div>
  );
}

export function CustomSearchHeader({ displayName }: { displayName?: string }) {
  return <div className={styles.customSearchtHeader}>{displayName}</div>;
}

const dropdownItems = [
  { text: 'CSVから検索する', value: 'csvSearch' },
  { text: '全件検索', value: 'searchAll' },
];

function Component() {
  usePageTitle('一括メンテ検索一覧');

  const tableMinWidth = (window.innerWidth - 100).toString();

  // refs
  const inputFile = useRef<HTMLInputElement | null>(null);

  // hooks
  const openMessageModal = useMessageModal();
  const downloadFile = useDownloadFile();

  // API's
  const { request: documentMaintenanceSearchRequest } = useDocumentMaintenanceSearchApi();
  const { request: searchListRequest, loading: searchListLoading } = useDocumentMaintenanceGetSearchListApi();
  const { request: downloadSearchResultFile, loading: downloadSearchResultFileLoading } = useDownloadSearchResultFileApi();
  const { request: createLog } = useCreateLogApi();

  // states
  const [searchList, setSearchList] = useState<SearchResultItem[]>();
  const [bulkTags, setBulkTags] = useState<File | null>(null);
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);

  // methods
  const onGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      setBulkTags(file);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const alertModalFunction = useCallback((text: string, onClose: () => void) => {
    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text,
      onCancel: () => { onClose(); },
    });
  }, [closeAlertModal]);

  const onCancel = useCallback(() => {
    setCloseAlertModal({
      ...closeAlertModal,
      open: false,
    });
  }, [closeAlertModal]);

  const getSearchList = useCallback(async () => {
    try {
      const res = await searchListRequest();
      setSearchList(res);
    } catch (e) {
      openMessageModal((e as Error)?.message);
    }
  }, [openMessageModal, searchListRequest]);

  const handleSearchAll = useCallback(() => {
    setAlertModal({
      open: true,
      text: '全件検索を実施します。\n60,000件を超える場合は検索できません。',
      onConfirm: async () => {
        setAlertModal({ ...alertModal, open: false });
        try {
          documentMaintenanceSearchRequest({ searchList: [] });
        } catch (e) {
          console.error(e);
        }
        alertModalFunction('検索を受け付けました。\n検索結果は一括メンテ検索一覧画面よりダウンロードできます。', () => {
          getSearchList();
          onCancel();
        });
      },
      onCancel: () => {
        setAlertModal({
          ...alertModal, open: false,
        });
      },
    });
  }, [alertModal, alertModalFunction, documentMaintenanceSearchRequest, getSearchList, onCancel, openMessageModal]);

  const searchOptions = useCallback((type: string) => {
    if (type === 'csvSearch') inputFile.current?.click();
    else if (type === 'searchAll') handleSearchAll();
  }, [handleSearchAll]);

  const onReloadClick = useCallback(() => {
    getSearchList();
  }, [getSearchList]);

  const onDownloadClick = useCallback(async (data: SearchResultItem) => {
    try {
      const searchResultFile = await downloadSearchResultFile(data.searchKey);
      downloadFile(searchResultFile);
      getSearchList();
    } catch (e) {
      openMessageModal((e as Error)?.message);
    }
  }, [downloadFile, downloadSearchResultFile, getSearchList, openMessageModal]);

  const columnDefs = [
    AGUtils.colDefault('searchKey', '検索処理番号', 200),
    AGUtils.colDate('registDate', '検索日時'),
    AGUtils.colDefault('registUser', 'ユーザー名', 200),
    {
      headerName: '検索結果',
      field: 'searchResult',
      width: 230,
      valueFormatter: (params: ValueFormatterParams) => {
        const {
          searchStatus, searchResult, errorReason, searchResultNumber,
        } = params.data;
        if (searchStatus === SearchStatus.IN_PROGRESS) {
          return '処理中';
        }
        if (searchStatus === SearchStatus.COMPLETED && searchResult === SearchResult.ERROR) {
          return `エラー${errorReason ? ` (${errorReason})` : ''}`;
        }
        if (searchStatus === SearchStatus.COMPLETED && searchResult === SearchResult.SUCCESS) {
          return `${searchResultNumber}件`;
        }
        return '';
      },
    },
    {
      headerName: '',
      resizable: true,
      field: 'condition',
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onDownloadClick,
        onReloadClick,
      },
      width: 170,
      headerComponentFramework: CustomSearchHeader,
    },
    AGUtils.colDefault('downloadCount', 'ダウンロード回数', 150, true, false, {
      valueFormatter: (params: any) => `${params.value} 回`,
    }),
    {
      field: '',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
    },
  ];

  // effects
  useEffect(() => {
    getSearchList();
  }, []);

  useEffectOnce(() => {
    createLog(LogFormName.BulkMaintenanceSearchList, LogControlName.Show);
  });

  return (
    <MainFrame body={(
      <div className={styles.mainFrame}>
        <Breadcrumb crumbs={[
          { label: '寺田倉庫用', route: routes.bpoMenuScreen },
          { label: document.title }]}
        />
        <div className={styles.mainFrameBody}>
          <div className={styles.bodyContentTop}>
            <div className={styles.bodyContentTopLeft}>
              <div>
                <DropdownMenu options={dropdownItems} right onSelect={searchOptions}>
                  <Button size="smaller" color="lighterGray">
                    <span>新規検索登録</span>
                    <span style={{ marginLeft: '10px' }}>▼</span>
                  </Button>
                </DropdownMenu>
              </div>
              <BaseText size="smMd" color="var(--danger)">
                検索結果は最大7日までダウンロード可能です。
              </BaseText>
            </div>
          </div>

          <div className={styles.bodyContentMain}>
            <div className={styles.bodyContentMainLeft}>
              <div style={{ height: '100%' }}>
                <BaseTable
                  formName="searchList"
                  rowData={searchList}
                  onGridReady={onGridReady}
                  minWidthProp={tableMinWidth}
                  columnDefs={columnDefs}
                />
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onFileSelected}
          accept=".csv"
        />
        {(searchListLoading || downloadSearchResultFileLoading) && <LoadingOverlay />}
        {bulkTags && (
          <BulkTagRegisterComponent
            bulkTagsFile={bulkTags}
            setBulkTagsFile={setBulkTags}
            reloadSearchList={getSearchList}
          />
        )}
        <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
        <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
      </div>
    )}
    />
  );
}

export default Component;
