import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card/card';
import usePageTitle from '../../hooks/title.hook';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './bpoMenuPage.module.css';
import routes from '../../utils/routes';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { useCreateLogApi } from '../../hooks/api/log.hook';

/**
 * BPOメニュー画面
 */
function Component() {
  usePageTitle('寺田倉庫用');
  const navigate = useNavigate();

  const { request: createLog } = useCreateLogApi();

  useEffectOnce(() => {
    createLog(LogFormName.MaintenanceMenu, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.container}>
          <div className={styles.mainContainer}>
            <div className={styles.row}>
              <Card cardImageType="files-line" text="一括メンテ検索" buttonText="設定する" handleButtonClick={() => navigate(routes.tagSearchResultsDownloadScreen)} />
              <Card cardImageType="files-line" text="一括メンテ実行" buttonText="設定する" handleButtonClick={() => navigate(routes.bulkTagsUpdateScreen)} />
              <Card cardImageType="files-line" text="一括メンテ結果" buttonText="設定する" handleButtonClick={() => navigate(routes.bulkMaintenanceResultsDownloadScreen)} />
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default Component;
