import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './mappingSelect.module.css';

interface SelectedOption {
  changedKey: string,
  changedOption: string,
}

interface Props {
  objKey: string;
  columnWidth: number;
  onOptionSelected?: (e: string, key: string, previousSelectedOption: string) => void;
  optionWasChanged: SelectedOption;
  selectOptions: any;
  showDocType?: boolean;
}

function MappingSelect({
  objKey, columnWidth, onOptionSelected, optionWasChanged, selectOptions, showDocType,
}: Props) {
  // States
  const [option, setOption] = useState<string>('');
  const [previousSelectedOption, setPreviousSelectedOption] = useState<string>('');
  const [optionType, setOptionType] = useState<string>('');

  // Effects
  useEffect(() => {
    // console.log('opTion Was Chaanged');
    if (optionWasChanged.changedKey === objKey) {
      return;
    }
    if (optionWasChanged.changedKey !== objKey && optionWasChanged.changedOption === option) {
      setOption('無視する');
      setPreviousSelectedOption('ignore');
      setOptionType('');
    }
  }, [optionWasChanged]);

  // Methods
  const optionWasSelected = (e: any, key: string) => {
    setOptionType(JSON.parse(e).type);
    if (onOptionSelected) onOptionSelected(e, key, previousSelectedOption);
    setPreviousSelectedOption(e);
    setOption(e);
  };

  return (
    <div>
      <select
        key={objKey}
        className={[styles.selectTable].join(' ')}
        style={{ minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px` }}
        onChange={(e) => optionWasSelected(e.target.value, objKey)}
        value={option}
      >
        {selectOptions.map((item: any) => (
          <option
            key={uuid()}
            value={JSON.stringify(item)}
          >
            {item.label}
          </option>
        ))}
      </select>
      {showDocType && (
      <div className={[styles.divBox].join(' ')} style={{ minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px`, borderTop: '0' }}>
        {option !== '無視する' ? optionType : ''}
      </div>
      )}
    </div>
  );
}

export default MappingSelect;
