import { useCallback, useState } from 'react';
import documentsMaintenanceApi, {
  BulkChangeItem, BulkChangeRegisterRequest, GetUpdateDateRequest, GetUpdateDateResponse,
  SearchRegisterForm,
  SearchResultItem,
} from '../../services/http/documentsMaintenance.api';
import { ApiError } from '../../services/http';

export function useDocumentMaintenanceSearchApi() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (form: SearchRegisterForm): Promise<void> => {
    setLoading(true);
    try {
      return await documentsMaintenanceApi.searchRegister(form);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request, loading,
  };
}

export function useDocumentMaintenanceGetSearchListApi() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (): Promise<SearchResultItem[]> => {
    setLoading(true);
    try {
      return await documentsMaintenanceApi.getSearchList();
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request,
    loading,
  };
}

export function useDocumentMaintenanceGetBulkChangeListApi() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (): Promise<BulkChangeItem[]> => {
    setLoading(true);
    try {
      return await documentsMaintenanceApi.getBulkChangeList();
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request,
    loading,
  };
}

export function useDownloadSearchResultFileApi() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (searchKey: string): Promise<File> => {
    setLoading(true);
    try {
      return await documentsMaintenanceApi.downloadSearchResultFile(searchKey);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request,
    loading,
  };
}

export function useDownloadBulkChangeErrorFileApi() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (changeKey: string): Promise<File> => {
    setLoading(true);
    try {
      return await documentsMaintenanceApi.downloadBulkChangeErrorFile(changeKey);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request,
    loading,
  };
}

export function useDocumentMaintenanceGetUpdateDateApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetUpdateDateResponse | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (requestData: GetUpdateDateRequest): Promise<GetUpdateDateResponse> => {
    setLoading(true);
    try {
      const res = await documentsMaintenanceApi.getUpdateDate(requestData);
      setData(res);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request,
    loading,
    data,
    error,
  };
}

export function useDocumentMaintenanceBulkChangeRegisterApi() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (form: BulkChangeRegisterRequest): Promise<void> => {
    setLoading(true);
    try {
      return await documentsMaintenanceApi.bulkChangeRegister(form);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request, loading,
  };
}
