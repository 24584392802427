import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import styles from './tagBulkAddCard.module.css';
import { TagBulk } from '../../services/http/tagBulk.api';
import TagFormat from '../../utils/tagFormat';
import mainStyles from '../main.module.css';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import { DropDownMenuItem } from '../../components/DropdownMenu';
import BaseTagInput from '../../components/BaseTagInput';

interface Props {
  allTags: TagBulk[],
  tag: TagBulk,
  onChangeTagValue?: (value: string, isValid: boolean) => void,
  onDeleteTag: () => void,
  onSelectTag: (tag: TagBulk | null) => void,
  onInputFocus?: () => void,
  type?: string,
  selectLabel?: string,
  typeLabel?: string,
}

const TagValidErrorTextMap: Record<TagFormat, string> = {
  [TagFormat.DATE]: '日付が不正です',
  [TagFormat.NUMBER]: '値が不正です',
  [TagFormat.STRING]: '',
};

export default function TagBulkAddCard({
  allTags,
  tag,
  onChangeTagValue,
  onDeleteTag,
  onSelectTag,
  onInputFocus = () => ({}),
  type,
  selectLabel = '変更対象タグ',
  typeLabel = '形式',
}: Props) {
  const [selectedTag, setSelectedTag] = useState<TagBulk | null>(null);
  // const [isTagValid, setIsTagValid] = useState<boolean>(true);
  const [showTagError, setShowTagError] = useState<boolean>(false);
  const isTagValid = useRef<boolean>(true);

  const currentOptions: DropDownMenuItem<TagBulk>[] = useMemo(() => {
    const tagOptions: DropDownMenuItem<TagBulk>[] = allTags.map((_tag) => ({ text: _tag.tagLabel, value: _tag }));
    if (!selectedTag) return tagOptions;
    const selectedTagOption: DropDownMenuItem<TagBulk> = { text: selectedTag.tagLabel, value: selectedTag };
    const tagOptionsWithSelectedTag = [selectedTagOption, ...tagOptions];
    return tagOptionsWithSelectedTag;
  }, [allTags, selectedTag]);

  const handleTagValueChange = useCallback((value: string, isValid: boolean) => {
    if (onChangeTagValue) {
      onChangeTagValue(value, isValid);
      isTagValid.current = isValid;
    }
  }, [onChangeTagValue]);

  const handleBlur = useCallback(() => {
    if (!isTagValid.current) {
      setShowTagError(true);
      return;
    }
    setShowTagError(false);
  }, [isTagValid]);

  const handleSelectTag = useCallback((val: TagBulk | null) => {
    setSelectedTag(val);
    onSelectTag(val);
    setShowTagError(false);
    isTagValid.current = true;
  }, [onSelectTag]);

  const TagFormatTextMap = TagFormat.getTextMap();

  return (
    <div className={styles.sideCardItem}>
      <div className={styles.sideCardItemRow}>
        <div className={[styles.flexCol, styles.autoCompleteBox].join(' ')}>
          <label>{selectLabel}</label>
          <AutoCompleteInput<TagBulk>
            deselectOption
            dropDownItemStyle={{ minWidth: '300px' }}
            options={currentOptions}
            onSelect={(val) => handleSelectTag(val)}
            onDeselect={() => handleSelectTag(null)}
            value={selectedTag ? selectedTag.tagLabel : ''}
            menuRight
          />

        </div>
        <div className={styles.formatCol}>
          {selectedTag ? (
            <>
              <div className={styles.formatLabel}>{typeLabel}</div>
              <div className={styles.formatText}>{type || TagFormatTextMap[selectedTag.format]}</div>
            </>
          ) : null}
        </div>
        <div>
          <img className={[styles.deleteCardButton].join(' ')} src="/images/Close-333333.svg" alt="" onClick={() => onDeleteTag()} />
        </div>
      </div>

      {onChangeTagValue && (
        <div className={styles.sideCardItemRow}>
          <div className={styles.inputCol}>
            <label>
              変更後の値
              {selectedTag && selectedTag.required ? (
                <span className={mainStyles['text-danger']} style={{ marginLeft: '10px' }}>※</span>
              ) : null}
            </label>
            <BaseTagInput
              tag={tag}
              className={styles.tagInput}
              onFocus={onInputFocus}
              onBlur={handleBlur}
              onChange={handleTagValueChange}
            />
            <div className={styles.errorContainer}>
              {showTagError ? (
                <span>
                  {TagValidErrorTextMap[tag.format]}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
